<template>
    <div>
        <app-layout>
	        <template v-slot:header>
		        <Header 
                    :title="$t('coop_and_public_scholarship')"
                    :isNewButton="checkPermission('scholarship_store')"
                    @new-button-click="showCreateForm()"
                    @filter-div-status="datatable.filterStatus = $event"
                ></Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile 
                    :title="$t('coop_and_public_scholarship')"
                    :isNewButton="checkPermission('scholarship_store')"
                    @new-button-click="showCreateForm()"
                    @filter-div-status="datatable.filterStatus = $event"
                ></HeaderMobile>
	        </template>
            
            <datatable-filter 
                v-show="datatable.filterStatus" 
                @filter="getRows" 
                @filterClear="filterClear" 
                :exportExcel="true" 
                @exportExcel="$refs.datatable.excel()"
            >
                <b-row>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('scholarship_type')">
                            <multi-selectbox 
                                v-model="datatable.queryParams.filter.type"
                                :multiple="false"
                                :options="scholarshipTypes"
                            ></multi-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('company_name')">
                            <b-form-input v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group :label="$t('scholarship_rate')">
                            <scholarship-rate-selectbox 
                                v-model="datatable.queryParams.filter.rank"
                                :rates="rateOptions"
                            ></scholarship-rate-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>

            <datatable 
                ref="datatable"
                :isLoading.sync="datatable.isLoading" 
                :columns="datatable.columns" 
                :rows="datatable.rows"
                :total="datatable.total" 
                :queryParams="datatable.queryParams" 
                :lineNumbers="false"
                @on-page-change="onPageChange" 
                @on-sort-change="onSortChange" 
                @on-per-page-change="onPerPageChange"
                v-show="datatable.showTable"
                :data-function="callService"
            ></datatable>

            <CommonModal ref="showModal" size="xl" :onHideOnlyX="true" @bHideModalHeaderClose="formClear">
                <template v-slot:CommonModalTitle>
                    {{ $t(modalTitle).toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <component v-if="modalComponent" :is="modalComponent" @actionSuccess="actionSuccess" :formId="formId"/>
                </template>
            </CommonModal>
            
        </app-layout>
    </div>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import DatatableFilter from '@/components/datatable/DatatableFilter'
import Datatable from '@/components/datatable/Datatable'
import MultiSelectbox from '../../../components/interactive-fields/MultiSelectbox'
import ScholarshipRateSelectbox from '@/components/interactive-fields/ScholarshipRateSelectbox'
import CoopPublicScholarshipService from '@/services/CoopPublicScholarshipService'
import CommonModal from '@/components/elements/CommonModal'
import CreateForm from './CreateForm'
import ShowForm from './ShowForm'
import UpdateForm from './UpdateForm'
import qs from 'qs'

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        MultiSelectbox,
        ScholarshipRateSelectbox,
        CommonModal,
        CreateForm,
        ShowForm,
        UpdateForm
    },
    metaInfo() {
        return {
            title: this.$t('coop_and_public_scholarship')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('show'),
                                class: 'ri-search-eye-line',
                                permission: 'scholarship_show',
                                callback: (row) => {
                                    this.showDetailForm(row.id)
                                },
                                show: () => {
                                    return this.checkPermission('scholarship_show')
                                }
                            },
                            {
                                text: this.$t('edit'),
                                class: 'ri-edit-box-line',
                                permission: "scholarship_update",
                                callback: (row) => {
                                    this.showUpdateForm(row.id)
                                },
                                show: () => {
                                    return this.checkPermission('scholarship_update')
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: "scholarship_delete",
                                callback: (row) => {
                                    this.formDelete(row.id)
                                },
                                show: () => {
                                    return this.checkPermission('scholarship_delete')
                                }
                            },
                        ]
                    },
                    {
                        label: this.$t('scholarship_type'),
                        field: 'type',
                        sortable: false,
                    },
                    {
                        label: this.$t('company_name'),
                        field: 'name',
                        sortable: false,
                    },
                    {
                        label: this.$t('scholarship_rate'),
                        field: 'rank',
                        sortable: false,
                    }
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: 20
                }
            },
            scholarshipTypes: [
                {
                    value: 'coop_scholarship', 
                    text: this.$t('coop_scholarship')
                }, 
                {
                    value:'public_scholarship', 
                    text: this.$t('public_scholarship')
                }
            ],
            rateOptions: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
            formId: null,
            modalTitle: null,
            modalComponent: null
        }
    },
    methods: {     
        callService(config) {
            return  CoopPublicScholarshipService.getAll(config)
        },
        getRows() {
            this.datatable.showTable = true
            this.datatable.isLoading = true

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            return CoopPublicScholarshipService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data.map((row) => {
                        const item = row
                        item.type = this.$t(item.type)
                        item.rank = '% ' + item.rank 
                        return item
                    })

                    this.datatable.total = response.data.pagination.total
                })
                .finally(() => {
                    this.datatable.isLoading = false
                })
        },
        filterClear() {
            this.datatable.queryParams.filter = {}

            this.getRows()
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage

            this.getRows()
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage
            this.datatable.queryParams.page = 1

            this.getRows()
        },
        onSortChange(params) {
            const sortType = params[0].type == 'desc' ? '-' : ''
            this.datatable.queryParams.sort = sortType + params[0].field

            this.getRows()
        },
        showCreateForm() {
            this.formId = null
            this.modalComponent = 'create-form'
            this.modalTitle = 'new'
            this.$refs.showModal.$refs.commonModal.show()
        },
        showDetailForm(id) {
            this.formId = id
            this.modalComponent = 'show-form'
            this.modalTitle = 'scholarship_detail'
            this.$refs.showModal.$refs.commonModal.show()
        },
        showUpdateForm(id) {
            this.formId = id
            this.modalComponent = 'update-form'
            this.modalTitle = 'edit'
            this.$refs.showModal.$refs.commonModal.show()
        },
        formDelete(id) {
            this.$swal
                .fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no')
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        CoopPublicScholarshipService.remove(id)
                            .then(response => {
                                this.$toast.success(this.$t('api.' + response.data.message))
                                this.getRows()
                                this.formClear()
                            })
                            .catch(error => {
                                this.$toast.error(this.$t('api.' + error.data.message))
                            })
                    }
                })
        },
        formClear() {
            this.formId = null
            this.modalComponent = null
            this.modalTitle = null
        },
        actionSuccess() {
            this.$refs.showModal.$refs.commonModal.hide()
            this.getRows()
            this.formClear()
        }
    }
}
</script>

